
.react-dropdown-tree-select .dropdown .dropdown-content {
    width: 100%;
    z-index: 100;
    max-height: 250px;
    overflow-y: auto
}

.tag-list{
    margin-right: auto;
}
  
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow{
    display: flex;
    justify-content: end;
}

.react-dropdown-tree-select .dropdown{
    width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after{
    margin-top: 5px;
    margin-right: 5px;
}

.tag{
    background-color: inherit;
    border: none;
}
.tag-remove{
    margin-left: 10px;
    font-size: 100%;
}