:root {
  --colorNeutralBackground1: #ffffff;
  --colorPaletteVioletBackground1: #1e004d;
  --colorPaletteVioletBackground2: #7562fc;
  --colorPaletteVioletBackground3: #6e31ff;
  --colorBrandForeground1: #1477f8;
  --colorBrandForeground2: #81aaff;
  --colorBrandForeground3: #005dea;
  --colorNeutralForegroundInverted1: #f2f0f4;
  --colorNeutralForegroundInverted2: #efefef;
  --colorNeutralForeground2: #707882;
  --colorNeutralForeground4: #f1f3f8;
  --colorNeutralForeground8: #f9fafc;
  --colorNeutralForeground1: #3e4450;
  --colorNeutralForeground7: #737880;
  --colorNeutralForeground5: #959ba2;
  --colorNeutralForeground9: #a6a8aa;
  --colorNeutralForeground10: #a2a2a2;
  --colorNeutralForegroundLightGray: #d9d9d9;
  --colorNeutralForeground11: #d9dde6;
  --colorNeutralForeground12: #FAB01B;
  --colorNeutralForeground13: #7492cf;
  --colorNeutralForeground14: #F9E4B9;
  --colorNeutralForeground6: #a9afc6;
  --colorNeutralBackgroundInverted2: #10141f;
  --colorNeutralBackgroundInverted5: #010202;
  --colorNeutralBackgroundInverted4: #273238;
  --colorNeutralBackgroundInverted3: #2a2b2f;
  --colorNeutralBackground3: #d9dde7;
  --colorPalleteLightBlue: #eeefff;
  --colorNeutralForeground3: #323232;
  --greenBorderColor: #83e1ab;
  --grenBackgroundColor: #dbffea;
  --grenBackgroundColor3: #d8faff;
  --grenBackgroundColor2: #f5feff;
  --colorPaletteYellowForeground1: #fcc658;
  --colorPaletteOpacityYellowForeground1: #f3dbac;
  --colorPaletteRedForeground1: #fa6b6b;
  --colorPaletteRedForeground3: #ff9e9e;
  --colorPalettePurpleForeground1: #db9eff;
  --colorPaletteRedForeground2: #bd0202;
  --colorPaletteRedForeground5: #fdf0d0;
  --colorPaletteRedForeground4: #f7a79e;
  --colorPalettePurpleBackground1: #a19afc;
  --colorPalettePurpleBackground2: #f9f9f9;
  --colorPaletteGreenBackground1: #1493a4;
  --colorPaletteGreenBackground2: #07c357;
  --colorPaletteGreenBackground3: #0cd196;
  --colorPaletteGreenBackground6: #9cf0c8;
  --colorPaletteGreenBackground4: #d8faff;
  --colorPaletteGreenBackground5: #96e2b9;
  --colorPaletteGreenBackground7: #e6f9ee;
  --colorPaletteGreenBackground8: #9CE7BC;
  --colorPaletteBlueBackground1: #cdd3ec;
  --colorPaletteBlueBackground2: #a9cdff;
  --colorPaletteBlueBackground3: #e4e9ff;
  --colorPaletteBlueBackground4: #edf0ff;
  --colorPaletteGrayBackground1: #f5f6fa;
  --colorPaletteGrayBackground2: #f2f0f4;
  --colorPaletteGrayBackground4: #ffb6b6;
  --colorNeutralBackground4: #f5f6fa;
  --colorNeutralBackground5: #eaeaea;
  --graySelectBoxBorder: #cdd3ec;
  --colorShadowInverted1: rgba(61, 66, 77, 0.09);
  --colorShadowInverted2: rgba(61, 66, 77, 0.9);
  --colorShadowInverted3: rgba(0, 0, 0, 0.38);
  --colorGrayBackGradient: linear-gradient(0deg, #cdd3ec, #cdd3ec);
  --colorGrayForeground6: #d8d8d8;
  --colorGrayForeground7: #f1f1f1;
  --colorPaletteGrayBackground3: #efedf1;
  --colorSearchResultBackground: #e1e8ff;
  --colorCross: #c7c6ca;
  --colorTooltipBackground: #2a2b2fd6;
  --colorTextAllButton: #474747;
  --colorScrollBgc: #EDEDED;
  --grayD9DDE7: #D9DDE7;
  --colorEmptyHotspotCell: #F1F1F1;
}
