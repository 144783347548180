.parent {
  transform: rotateX(180deg);
  overflow-x: auto;
}
.child {
  transform: rotateX(180deg);
}


.parent::-webkit-scrollbar {
  height: 8px; /* or the width you want */
  border-radius: 4px;
}

.parent::-webkit-scrollbar-track {
  background: #f1f1f1; /* the color of the track background */
  border-radius: 4px;
}

.parent::-webkit-scrollbar-thumb {
  background: #A9AFC6; /* the color of the scroll handle */
  border-radius: 4px;
}

.parent::-webkit-scrollbar-thumb:hover {
  background: #8d91a2; /* the color of the scroll handle on hover */
}
